import axiosInstance from '../../lib/axios';
import urls from '../';

// ## TITLE
export const postUpdateTitle = (
    base: any,
    data: any,
    client_id: any,
    extra: any,
    auth: any
) => {
    const urll = `${base}/${urls.postUpdateTitle}`;
    return axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        return { status: response.status };
    });
}
