import axiosInstance from '../../lib/axios';
import urls from '../';

// ## SLIDE ADD
export const postAddImageSlide = (
    base: any,
    payload: any,
    client_id: any,
    extra: any,
    auth: any,
    state: any
) => {
    const { sslides, setSlides, activeSlide, setActiveSlide, localMenuSlideEditValueUpdate, setCurrent } = state;
    const urll = `${base}/${urls.postAddImageSlide}`;
    return axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const { status, data: { data } } = response;

        if (data) {
            let addedSlideList: any = [];
            addedSlideList = [...sslides];
            const idx = activeSlide ? activeSlide + 1 : 1;
            addedSlideList.splice(idx, 0, data);
            setSlides(addedSlideList);
            setActiveSlide(idx);
            // changeCurrent(addedSlideList[idx]);
            // console.log(addedSlideList[idx]);
            if (idx) {
                localMenuSlideEditValueUpdate(addedSlideList[idx], 'background_image')
            }
            setCurrent(addedSlideList[idx]);
        }
        return { status, data };
    });
}
