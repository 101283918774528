// src/component/Panel/Logos/LogoItem.tsx
import React, { useEffect, useState } from 'react';

interface LogoItemProps {
    label: string;
    pass: any;
    color: any;
    checkStatus: Boolean | undefined;
    colorpick: (obj: any) => void;
    onLogoChange?: any;
    display: any;
    background: any;
}

function LogoItem({ label, pass, color, checkStatus, colorpick, onLogoChange, display, background }: LogoItemProps) {
    const [locColor, setLocColor] = useState(color);
    const [locCheck, setLocCheck] = useState(checkStatus);

    useEffect(() => {
        setLocCheck(checkStatus);
    }, [checkStatus])
    useEffect(() => {
        setLocColor(color);
    }, [color])

    const style = { background: locColor };

    const changeCheck = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        onLogoChange({ type: 'visible', value: target.checked });
    };

    const pickColor = () => {
        colorpick({ color: locColor, name: pass });
    };

    return (
        <div className="logo__element">
            {display.visible && (
                <div className="checkbox__wrapper">
                    <label className="flex setting__element__logo check__container">
                        <input
                            type="checkbox"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeCheck(e)}
                            defaultChecked={locCheck ? true : false}
                        />
                        <span className="checkmark"></span>
                        {label || display.label}
                    </label>
                </div>
            )}
            {background.visible && (
                <div className="flex setting__element__color" onClick={pickColor}>
                    <span className="setting__element__colorpicker" style={style}></span>
                    <span className="setting__element__colorpicker__label"> {background.label} </span>
                </div>
            )}
            <br />
        </div>
    );
}
export default LogoItem;
