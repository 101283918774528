import React, { useEffect, useState, useContext } from "react";
// import { cloneDeep } from "lodash";
import { deepCloneObject } from "../../lib/lodash";
import EditableItemCell from "./EditableItemCell";
import EditableBox from "./EdiatableBox";
import EditableMargin from "./EditableMargin";
import EditableQuantity from "./EdiatableQuantity";
import ToggleButton from './ToggleButton'
// import EditableFreight from './EditableFreight'
import { editableResetIcon, editableCloseIcon } from "../../helpers/IconList";
import EditEcqContext from "../../context/EditEcqContext";
import languageContext from "../../context/languageContext";

import EditableForm from "./EditableForm";
import {
    changeRow,
    ecqSubmit,
    replaceDecimalWithComma,
    // replaceCommaWithDecimal,
    // placeCurrency,
    decimalPlacing,
    decimalAndCurrency,
} from "./edithelp";

import { EditablePropsType } from "./typeEditable";

function Editable({
    ecqdata,
    defaultdata,
    defaultmore,
    setView,
    currency,
    more,
    total,
    onUpdate,
    product_no
}: EditablePropsType) {
    const newEcqData = deepCloneObject(ecqdata);
    const [cell, setCell] = useState<string | null>(null);
    const [localState, setLocalState] = useState<any | null>(newEcqData);
    const [localmore, setLocalmore] = useState<any | null>(more);
    const [cur] = useState(currency);
    const [add, setAdd] = useState({
        title: "Add new price line",
        mode: 0,
    });
    const [statelimit, morelimit] = [15, 3];
    const [description, setDesccription] = useState(false);
    const [priceflag, setPriceflag] = useState(false);
    const [localToggle, setLocalToggle] = useState(false);

    const { font_color = "", template_color = "" }: any = useContext(
        EditEcqContext
    );
    const translate: any = useContext(languageContext);
    const { 
        edit_price_ticket,
        edit_price_description,
        description_bar,
        quantity_bar,
        unit_price_bar,
        total_price_bar,
        magin,
        original_price,
        total_exclusive_vat_label_text,
        price_per_product_label_text,
        reset_to_default,
        cant_add_more_item,
        cancel,
        ok, 
        add_new_price_line
    } = translate;

    const headerStyle = {
        background: template_color,
    };
    const borderStyle = {
        borderBottomColor: template_color,
    };
    const fontStyle = {
        color: font_color,
    };

    useEffect(() => {
        document.body.classList.add("modal-open");
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, []);
    useEffect(() => {
        more.map((m: any) => {
            if (m.isItem)
                setLocalToggle(true)
            return null;
        })
    }, [more]);

    useEffect(() => {
        const newEcqData = deepCloneObject(ecqdata);
        const commalist: any = newEcqData.map((n: any) => {
            const obj = {
                ...n,
                original: n.original.toString().replace('.', ','),
                unit_price: (n['unit_price']).toString().replace('.', ','),
                margin: decimalPlacing(n.margin)
            };
            // console.log('obj', obj);
            return obj || null;
        })
        setLocalState(commalist);
    }, [ecqdata]);

    let pricePerProduct: any = 0;
    total.map((tot: any) => {
        if (tot.code === "price_per_product") pricePerProduct = tot.charge;
        return null;
    });
    // Close Editable pop window
    function closeECQ() {
        setLocalState(ecqdata);
        setView(false);
    }
    /**
     * * Delete more pricerow
     * * Considering label is unique in more price section
     */
    function moredelete(label: string) {
        setLocalmore(localmore.filter((ld: any) => ld.label !== label));
    }
    /**
     * * Deleted row with sku
     * @param sku {string} unique value
     */
    function deleteEcqRow(sku: string) {
        setLocalState(localState.filter((ld: any) => ld.sku !== sku));
    }

    function checkFlags(list: any) {
        const flag1: any = list.some(({ attribute_label }: any) => {
            return attribute_label === ""
        });
        setDesccription(flag1);
        const flag2: any = list.some(({ margin }: any) => {
            return margin < 0;
        });
        setPriceflag(flag2);
    }
    function checkFlags2(list: any) {
        const flag1: any = list.some(({ label }: any) => {
            return label === ""
        });
        setDesccription(flag1);
        const flag2: any = list.some(({ margin }: any) => {
            return margin < 0;
        });
        setPriceflag(flag2);
    }
    /**
     * * Change reflection on Editable table
     * @param sku {string} unique value
     * @param val value to be replace with
     * @param name key above val belongs to
     */
    function changeEcqRow(sku: string, val: number, name: string) {
        const newArray = localState.slice();
        const nn: any = changeRow(sku, val, name, newArray);

        checkFlags(nn);

        //* UPDATE VALUE SETTING IN localState
        setLocalState(nn);
    }

    function changeMoreRow(sku: string, val: number, name: string) {
        const newArray = localmore.slice();
        const nn: any = changeRow(sku, val, name, newArray);
        checkFlags2(nn);
        setLocalmore(nn);
    }

    function addForm(type: number) {
        setAdd({ ...add, mode: type });
    }

    function rowAdd(obj: any) {
        if (add.mode === 1) {
            /**
             * attribute_label, code, price, qty, unit_price, original, editedWith // = "", sku, margin
             */
            setLocalState([...localState, obj]);
        } else {
            /**
             * change, label - unique
             */
            setLocalmore([...localmore, obj]);
        }
    }
    /**
     * * Reset to default function to set the editable window value to initial values ( provided in response )
     */
    function onResetToDefault() {
        const newEcqData = deepCloneObject(defaultdata);
        const nn: any = defaultdata.map((nnn: any) => { return { ...nnn, margin: '0.00' }});
        checkFlags(newEcqData);
        setLocalState(nn);
        setLocalmore(defaultmore);
        // onUpdate({ reset_to_default: true });
    }

    let totalComputing = 0;
    let itemsQty = 0;
    /**
     * * Chcnge in editable will be submite for change
     */
    function onEcqSubmit() {
        let msg = 'Invalide value found in table.';

        if (priceflag || description) {
            alert(msg);
        } else {

            // let computedTotal = JSON.parse(JSON.stringify(total)); // deepCloneObject(total);
            // console.log(JSON.stringify(total));

            const lllmmm: any = localmore.map((lm: any) => {
                if (lm.toggle)
                    return { ...lm, isItem: localToggle };
                return { ...lm, isItem: false };
            });

            if (localToggle) {
                const newtotal:any = [];
                newtotal.push({
                    code: "total_excl_vat",
                    label: "Total excl VAT",
                    charge: totalComputing
                });
                newtotal.push({
                    code: "price_per_product",
                    label: "Price per product",
                    charge: totalComputing / itemsQty
                });
                onUpdate(ecqSubmit(localState, lllmmm, newtotal, product_no));
                // setView(false);
            } else {
                
                let requesTot = 0;
                localState.map((row:any) => {
                    requesTot+=row.price;
                    return null;
                })
                const newTotal: any = [];
                newTotal.push({
                    code: "total_excl_vat",
                    label: "Total excl VAT",
                    charge: requesTot
                });
                newTotal.push({
                    code: "price_per_product",
                    label: "Price per product",
                    charge: totalComputing / itemsQty
                });

                // const newTotal = total.map((tot: any) => {
                //     if (tot.code === "total_excl_vat") {
                //         tot.charge = requesTot;
                //     } else {
                //         tot.charge = totalComputing/itemsQty
                //     }
                //     return tot;
                // });
                onUpdate(ecqSubmit(localState, lllmmm, newTotal, product_no));
            }
            setView(false);
        }
    }
    const limitCross = () => alert(cant_add_more_item);

    const bottomborderline = {
        borderBottom: `2px solid ${template_color}`
    }

    function pppc(total: any, qty: any, cur: any) {
        const cal = total / qty;
        return decimalAndCurrency(
            cal,
            cur
        ) || "";
    }

    function presentTotal() {
        return decimalAndCurrency(totalComputing, cur);
    }

    return (
        <React.Fragment>
            <div className="editBoxWrap">
                <div className="editscroll">
                    <div className="editBox">
                        <div className="backgroundBox__title">
                            <h4 style={fontStyle}>{edit_price_ticket}</h4>
                            <button onClick={closeECQ}>
                                <img src={editableCloseIcon} alt="close" />
                            </button>
                        </div>
                        <div className="backgroundBox__body">
                            <p style={fontStyle}>
                                {edit_price_description}
                            </p>
                            
                            {(description || priceflag) && (
                                <p className="errorform">
                                    {description &&
                                        <span><b>Error:</b> Description can't be empty.<br /></span>
                                    }
                                    {priceflag &&
                                        <span><b>Error:</b> Price can't be less than original price.<br /></span>
                                    }
                                </p>
                            )}

                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th style={headerStyle}>{description_bar}</th>
                                        <th style={headerStyle}>{quantity_bar}</th>
                                        <th style={headerStyle}>{unit_price_bar}</th>
                                        <th style={headerStyle}>{total_price_bar}</th>
                                        <th className="green-one">
                                            {magin}
                                        </th>
                                        <th className="green-one">
                                            {original_price}
                                        </th>
                                        <th className="green-two"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {localState.map(
                                        ({
                                            sku,
                                            attribute_label,
                                            qty,
                                            unit_price,
                                            price,
                                            margin,
                                            original,
                                            type,
                                            color = null,
                                            ...e
                                        }: any) => {
                                            if (price) totalComputing += price;
                                            // console.log(
                                            //     "totalComputing",
                                            //     totalComputing
                                            // );
                                            if (type === "items") {
                                                itemsQty += qty;
                                            }
                                            // console.log(
                                            //     decimalAndCurrency(price, cur)
                                            // );
                                            // console.info("------------");
                                            const emptyLabel = attribute_label === "";
                                            const negMargin = margin < 0;
                                            return (
                                                <tr key={sku}>
                                                    <td className={emptyLabel ? 'tderror' : ''}>
                                                        <EditableItemCell
                                                            style={fontStyle}
                                                            sku={sku}
                                                            {...e}
                                                            name="attribute_label"
                                                            change={
                                                                changeEcqRow
                                                            }
                                                            setCell={setCell}
                                                        >
                                                            {attribute_label}
                                                        </EditableItemCell>
                                                        {color &&
                                                            color.image && (
                                                                <img
                                                                    src={
                                                                        color.image
                                                                    }
                                                                    alt={
                                                                        color.label
                                                                    }
                                                                    width="17"
                                                                />
                                                            )}
                                                    </td>
                                                    <td>
                                                        <EditableQuantity
                                                            style={fontStyle}
                                                            sku={sku}
                                                            {...e}
                                                            name="qty"
                                                            change={
                                                                changeEcqRow
                                                            }
                                                            setCell={setCell}
                                                            original={1}
                                                            trail="x"
                                                        >
                                                            {qty}
                                                        </EditableQuantity>
                                                    </td>
                                                    <td className={negMargin ? 'tderror' : ''}>
                                                        <EditableBox
                                                            cur={cur}
                                                            style={fontStyle}
                                                            sku={sku}
                                                            {...e}
                                                            name="unit_price"
                                                            change={
                                                                changeEcqRow
                                                            }
                                                            setCell={setCell}
                                                            celvalue={cell}
                                                            original={original}
                                                        >
                                                            {replaceDecimalWithComma(unit_price) || unit_price}
                                                        </EditableBox>
                                                    </td>
                                                    <td style={{}}>
                                                        {price &&
                                                            decimalAndCurrency(
                                                                price,
                                                                cur
                                                            )}
                                                    </td>
                                                    <td>
                                                        <EditableMargin
                                                            cur={cur}
                                                            style={fontStyle}
                                                            sku={sku}
                                                            {...e}
                                                            name="margin"
                                                            change={
                                                                changeEcqRow
                                                            }
                                                            setCell={setCell}
                                                            original={0}
                                                        >
                                                            {margin}
                                                        </EditableMargin>
                                                    </td>
                                                    <td style={fontStyle}>
                                                        {original &&
                                                            decimalAndCurrency(
                                                                original,
                                                                cur
                                                            )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() =>
                                                                deleteEcqRow(
                                                                    sku
                                                                )
                                                            }
                                                            className="delete"
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    
                                    {localToggle && localmore.map((lm: any) => {
                                        // console.log(lm);
                                        if (localToggle && lm.toggle) {
                                            // setIncludeFlag(true);
                                            totalComputing += lm.charge;
                                            return <tr key={Math.random()} className="more-items">
                                                <td
                                                    style={fontStyle}
                                                >
                                                    {lm.label}
                                                </td>
                                                <td />
                                                <td>
                                                    {/* <EditableFreight charge={lm.charge} /> */}
                                                </td>
                                                <td>
                                                    {
                                                        decimalAndCurrency(lm.charge, cur)
                                                    }
                                                </td>
                                                <td />
                                                <td />
                                                <td />
                                            </tr>;
                                        }
                                        return null;
                                    })}

                                    <tr className="tblSpace">
                                        <td style={borderStyle} />
                                        <td style={bottomborderline} />
                                        <td style={bottomborderline} />
                                        <td style={bottomborderline} />
                                        <td />
                                        <td />
                                        <td>
                                            <button
                                                onClick={() => {
                                                    if (
                                                        localState &&
                                                        localState.length <
                                                        statelimit
                                                    )
                                                        addForm(1);
                                                    else limitCross();
                                                }}
                                                className="add"
                                            />
                                        </td>
                                    </tr>

                                    
                                    
                                    {/*  TOTAL EXCL. VAT */}
                                    <tr className="totalExcVAT">
                                        <td style={fontStyle}>
                                            {total_exclusive_vat_label_text}
                                        </td>
                                        <td colSpan={3} style={fontStyle}>
                                            {/* {(cur).replace('%s', (totalComputing).toFixed(2))} */}
                                            {totalComputing && presentTotal()}
                                        </td>
                                    </tr>
                                    <tr className="pricePerProduct">
                                        <td style={headerStyle}>
                                            {price_per_product_label_text}
                                        </td>
                                        <td colSpan={3}>
                                            {pricePerProduct &&
                                                pppc(totalComputing, itemsQty, cur)
                                            }
                                        </td>
                                    </tr>
                                    {localmore &&
                                        localmore.map(
                                            ({ label,
                                                toggle,
                                                charge,
                                                sku = "",
                                                price = "",
                                                unit_price = "-",
                                                margin = "-",
                                                original = "-",
                                                qty = "-",
                                                // ...e
                                            }: any) => {
                                                // console.log(toggle, localToggle, localmore)

                                                if (toggle && localToggle) return null;
                                                const emptyLabel = label === "";
                                                const negMargin = margin === "-"
                                                    ? false
                                                    : margin < 0;
                                                return (
                                                    <tr
                                                        key={sku || label}
                                                        className="more-items"
                                                    >
                                                        <td className={emptyLabel ? 'tderror' : ''} style={fontStyle}>
                                                            {sku === ''
                                                                ? label
                                                                :
                                                                <EditableItemCell
                                                                    style={fontStyle}
                                                                    sku={sku}
                                                                    name="label"
                                                                    change={
                                                                        changeMoreRow
                                                                    }
                                                                    setCell={setCell}
                                                                >
                                                                    {label}
                                                                </EditableItemCell>
                                                            }
                                                        </td>
                                                        <td
                                                            className="tdwhite"
                                                            style={fontStyle}
                                                        >   {sku === ''
                                                            ? qty :
                                                            <EditableQuantity
                                                                style={fontStyle}
                                                                sku={sku}
                                                                name="qty"
                                                                change={
                                                                    changeMoreRow
                                                                }
                                                                setCell={setCell}
                                                                original={1}
                                                                trail="x"
                                                            >
                                                                {qty}
                                                            </EditableQuantity>
                                                            }
                                                        </td>
                                                        <td
                                                            className={negMargin ? 'tdwhite tderror' : 'tdwhite'}
                                                            style={fontStyle}
                                                            data-title={negMargin}
                                                        >
                                                            {sku === ''
                                                                ? unit_price : <EditableBox
                                                                    cur={cur}
                                                                    style={fontStyle}
                                                                    sku={sku}
                                                                    name="unit_price"
                                                                    change={
                                                                        changeMoreRow
                                                                    }
                                                                    setCell={setCell}
                                                                    celvalue={cell}
                                                                    original={original}
                                                                >
                                                                    {unit_price}
                                                                </EditableBox>}
                                                        </td>
                                                        <td
                                                            className="tdw hite"
                                                            style={fontStyle}
                                                        >
                                                            {price
                                                                ? decimalAndCurrency(price, cur)
                                                                : charge
                                                                    ? decimalAndCurrency(charge, cur)
                                                                    : ''
                                                            }
                                                        </td>
                                                        <td style={fontStyle}>{sku === ''
                                                            ? margin
                                                            : <EditableMargin
                                                                cur={cur}
                                                                style={fontStyle}
                                                                sku={sku}
                                                                name="margin"
                                                                change={
                                                                    changeMoreRow
                                                                }
                                                                setCell={setCell}
                                                                original={0}
                                                            >
                                                                {margin}
                                                            </EditableMargin>
                                                        }
                                                        </td>
                                                        <td style={fontStyle}>
                                                            {original === '-'
                                                                ? original
                                                                : decimalAndCurrency(
                                                                    original,
                                                                    cur
                                                                )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                onClick={() => {
                                                                    moredelete(
                                                                        label
                                                                    );
                                                                }}
                                                                className="delete"
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        )}
                                    <tr>
                                        <td className="bordernone"></td>
                                        <td className="tdgret bordernone"></td>
                                        <td className="tdgret bordernone"></td>
                                        <td className="tdgret bordernone"></td>
                                        <td className="tdyellow bordernone"></td>
                                        <td className="tdyellow bordernone"></td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    if (
                                                        localmore &&
                                                        localmore.length <
                                                        morelimit
                                                    )
                                                        addForm(2);
                                                    else limitCross();
                                                }}
                                                className="add"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* {cell} */}
                            <ToggleButton flag={localToggle} set={setLocalToggle} fontStyle={fontStyle} />
                        </div>
                        <div className="backgroundBox__footer editBox__footer">
                            <div>
                                <button
                                    className="flex local__reset"
                                    onClick={onResetToDefault}
                                >
                                    <img
                                        src={editableResetIcon}
                                        alt="reset to default"
                                    />
                                    {reset_to_default}
                                </button>
                            </div>
                            <div>
                                <button onClick={closeECQ}>{cancel}</button>
                                <button
                                    className="ok"
                                    onClick={onEcqSubmit}
                                    disabled={false}
                                >
                                    {ok}
                                </button>
                            </div>
                        </div>
                    </div>
                    {add.mode !== 0 && (
                        <EditableForm
                            title={add_new_price_line || add.title}
                            mode={add.mode}
                            close={() => {
                                setAdd({ ...add, mode: 0 });
                            }}
                            rowAdd={rowAdd}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export default Editable;
