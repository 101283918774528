import React, { useContext } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import { publicPathLocal } from '../../helpers';
import languageContext from '../../context/languageContext';
import boardContext from '../../context/BoardContext';
// import LayoutContext from '../../context/LayoutContext';
import { BackgroundImageProp } from './shape';

const BackgroundImage = ({
  _id,
  draw_value,
  visible,
  element_id,
  height,
  width,
  x_pos,
  y_pos,
  group,
  localmenuView,
  slideType,
  currentid,
  currenttype
}: BackgroundImageProp) => {
  let style = {
    height,
    width,
    left: x_pos,
    top: y_pos
  };
  let style2 = {
    height,
    width,
    left: x_pos,
    top: y_pos
  };
  const { select_background = ""}: any = useContext(languageContext);
  const context: any = useContext(boardContext);
  const csshoveflat = context.localEdit && 'background_image' === context.localEdit.element_id;
  
  
  // useEffect(() => {
  //   // if (currentid !== null && currentid === _id) 
  //   // {
  //     console.log({ currentid, _id, currenttype, element_id });
  //     // localmenuView({ _id, element_id, group, slideType });
  //   // }
      
  // }, [currentid, currenttype, _id, element_id, group, slideType])
  if (currentid === null) return null;

  

  // useEffect(() => {
  //   console.log(_id, element_id, group, slideType);
  //   localmenuView({ _id, element_id, group, slideType });
  // }, [_id, element_id, group, slideType])
  // const { templateId, template}: any = useContext(LayoutContext);
  // let layoutname = '';
  // if (template.length) {
    // template.map((t: any) => {
      // if (t._id === templateId) {
        // layoutname = t.template_type;
      // }

      // return null;
    // })
  // }
  //* background change button will appear only if slide type matches
  const selectBackgroundFlag = slideType === 'image_slide' || slideType === 'image_slide2' || slideType === 'title_slide';
  const contactFlag = slideType === 'contact_slide';
  const productFlag = slideType === 'product_slide';
  const backgroundImageCss = (contactFlag || productFlag) ? '' : csshoveflat ? 'nohoverbackground' : 'backgroundimage';

  if (element_id !== 'background_image'
      && element_id !== "decorator_product_image_background"
      && element_id !== "left_large_image"
      && element_id !== "ecq_top_background") return null;
  if (element_id === "decorator_product_image_background" || element_id === "ecq_top_background") {
    style2 = {...style2, width: '100%', height: '100%' };
  }

  return (
    <div className={`${backgroundImageCss} absolute h-full`} style={style}>
      {(!visible) ? null : <img src = {`${draw_value}`} style={style2} alt={draw_value} />}
      
      {selectBackgroundFlag && (  
        <button
          key={_id}
          className="editbackgroundimage absolute"
          onClick={() => {
            window.scrollTo(0, 0);
            localmenuView({ _id, element_id, group, slideType});
          }}
        >
          <span>{select_background}</span>
        </button>
      )}
    </div>
  )
}
export default BackgroundImage;