import axiosInstance from '../../lib/axios';
import urls from '../';

export const getThemes = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    presentation_type: string
    ) => {
    const urll = `${base}/${urls.getThemes}`;
    return axiosInstance(urll, {
        method: 'get',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            presentation_type,
        },
    }).then((response: any) => {
        const { status, data } = response;
        return { status, data: data.data };
    });
}
