// src/component/Panel/ProductSlideDesign/index.tsx
import React, { useState, useContext, useEffect } from 'react'

import lookAndFeelContext from '../../../context/LookAndFeelContext';
import languageContext from '../../../context/languageContext';

export default function SlideDesing({ colorpick }: any) {
    const { element, design, onDesignChangge, lookFeel }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    const { font_color } = lookFeel;

    const [ele, setele] = useState(design);

    // useEffect(() => setele(design), [design]);

    useEffect(() => {
        const sortedDesign = [...design].sort((a: any, b: any) => {
            if (b.order === undefined) return -1;
            return a.order > b.order ? 1 : -1;
        });
        setele(sortedDesign);
    }, [design]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        onDesignChangge({ _id: name, value: checked })
    }
    const pickColor2 = () => {
        if (element.colors.value.style_1.main.colors)
            colorpick({ color: font_color, name: 'font_color', list: element.colors.value.style_1.main.colors });
        else
            colorpick({ color: font_color, name: 'font_color' });
    };

    return (
        <div className="setting__element">
            <div className="setting__element__wrap">
                <h3 className="gotham-black fz16 fw-normal mb15">{language["Content"] ? language["Content"] : "Content"}</h3>
                <ul className='list-style-none'>
                    { ele
                        .filter((d: any) => d.group === "content")
                        .map((d: any) => (
                        <li key={d._id} className='fz14 mb10'>
                            <div className={"flex justify-space-between text-black"}>
                                {language[d.element_id] ? language[d.element_id] : d.label}
                                <label className="cc_switch flex-shink-0">
                                    <input type="checkbox" checked={d.value} name={d._id} onChange={onChange} />
                                    <span className="slider_pillow cursor-pointer round"></span>
                                </label>
                            </div>
                        </li>
                    )
                    )}
                </ul>

                <div className='setting__divider'>
                    <h3 className="gotham-black fz16 fw-normal">{language["pillow_font"] ? language["pillow_font"] : "Font"}</h3>
                </div>
                <div className="flex setting__element__color_pillow" onClick={pickColor2}>
                    <span className="setting__element__font_colorpicker" style={{ background: font_color }}></span>
                    <span className='text-black flex1'>{language["font_color"] ? language["font_color"] : "Font color"}</span>
                </div>
            </div>
        </div>
    );
}
