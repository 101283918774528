// src/component/Panel/ProductSlideLayout/index.tsx
import React, { useContext } from 'react';

import lookAndFeelContext from '../../../context/LookAndFeelContext';
import languageContext from '../../../context/languageContext';

import Thumb from './Thumb';

const SlideLayout = () => {
    const { template, templateId }: any = useContext(lookAndFeelContext);
    const { select_layout }: any = useContext(languageContext);

    return (
        <div className="setting__element slideTemplate">
            <div className="setting__element__wrap">
                <h4 className="slideElement__h4">{select_layout}</h4>
                {template.map(({ _id, thumb, template_type, name }: any) => <Thumb key={_id} id={_id} template_type={template_type} thumb={thumb} flag={_id === templateId} name={name} />)}
            </div>
        </div>
    )
}
export default SlideLayout;
