// src/component/ThreeDModel/ThreeDModel.tsx
import React from 'react';
import { threedbox } from '../../helpers/IconList';

interface ThreeDModelType {
    width: string;
    height: string;
    x_pos: string;
    y_pos: string;
    draw_value: string;
}

const ThreeDModel = ({ width, height, x_pos, y_pos, draw_value }: ThreeDModelType) => (
    <div
        style={{
            height,
            width,
            left: x_pos,
            top: y_pos
        }} className="absolute threeDmodelwrap"
    >
        <a
            className='threeDanchor'
            target='_blank'
            rel="noopener noreferrer"
            href={draw_value}
            title="3D Model Link"
        >
            <img src={threedbox} alt={draw_value} />
        </a>
    </div>
);
export default React.memo(ThreeDModel);
