// src/component/Panel/ShapeColor/index.tsx
import React, { useState, useContext } from 'react'
import languageContext from '../../../context/languageContext';

import ShapeTab from './ShapeTab';
import ColorTab from './ColorTab';
import './shapeColor.css';

interface SlideEleentsProps {
    colorpick: (flag: boolean) => void
}

const ShapeColor = ({ colorpick }: SlideEleentsProps) => {
    const language: any = useContext(languageContext);
    const elementsTab = {
        active: 1,
        tabs: [
            { id: 1, label: "pillow_shapes" },
            { id: 2, label: "pillow_colours" }
        ]
    };

    const [tabDisplay, setTabDisplay] = useState(elementsTab);

    return (
        <div className="shapeColor">
            <div className='flex w-full'>
                {tabDisplay.tabs.map(({ label, id }: any) => {
                    return <div key={id} role="button" onClick={() => setTabDisplay((olddata: any) => ({ ...olddata, active: id }))}
                        className={`gotham-medium flex flex1 justify-center align-center h-50 shapeColor-tab ${(tabDisplay.active !== id ? 'cursor-pointer' : 'active')}`}>
                        {language[label]}
                    </div>
                })}
            </div>
            <div className='pt-20 setting__element shapeColor-content-wrap'>
                <div>
                    <div className='tab_content'>
                        {tabDisplay.active !== 1 ? <ColorTab colorpick={colorpick} /> : <ShapeTab />}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ShapeColor;
