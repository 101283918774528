import axiosInstance from '../../lib/axios';
import urls from '../';

export const saveDefaultTheme = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) => {
    const urll = `${base}/${urls.saveDefaultTheme}`;
    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const {
            data: { success, message },
            status,
        } = response;
        return { status, success, message };
    });
}