import React from 'react'

import './button.css';

type ButtonType = {
    children: any;
    sty?: string;
    click?: any;
}

const Button = ({ children, click, sty = 'btn-ok' }: ButtonType) => (
    <div className="outer">
        <div className={`button ${sty}`} role="button" onClick={click}>
            <div className="text">{children}</div>
        </div>
    </div>
)

export default Button;
