import axiosInstance from '../../lib/axios';
import urls from '../';

// ## OVERVIEW PAGE
export const getUserPresentations = (
    base: string,
    client_id: any,
    extra: any,
    auth: any,
    presentation_type: any,
    page: any,
    limit: any,
    sort: any,
    sortby: any,
    old_customer_id: any
) => {
    const urll = `${base}/${urls.getUserPresentations}`;
    return axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        params: {
            page,
            limit,
            sort,
            sortby,
            presentation_type,
            old_customer_id,
        },
    }).then((response: any) => {
        const { data: { total, presentations } } = response.data;
        return { status: response.status, total, presentations }; // response.json();
    });
}
