// src/component/LocalMenu/LocalMenu.tsx
/**
 * * LOCAL MENU
 * * -------------------------------
 * * Init some element should be in edit mode to see option like
 * * background color or background image selection
 * * Product Slide :: Layout option display
 */
import React, { useState, useContext, useEffect } from 'react';

import boardContext from '../../context/BoardContext';
import languageContext from '../../context/languageContext';

import { BACKGROUND_IMAGE, PRESENTATION_LOGO, END_CUSTOMER_LOGO, END_CUSTOMER_LOGO_BG, TITLE_TEXT, TITLE_TEXT_BACKGROUND } from '../../constant';

import { localImageIcon, localResetIcon, localCorner } from '../../helpers/IconList';

import TipDisplay from '../Tip';
//localCloseIcon, 
interface LocalMenuProps {
    localEdit?: any;
    colorpickview: any;
    onBackgroundChange: any;
    elements: any;
    onSlideUpdate: any;
    onResetApi: any;

    current?: any;
    layout?: any;
    templateId?: any;
    onTemplateChange?: any;
    setTemplateId?: any;
    typeName: any;
}
// TODO :: Change Background (Image or color), reset to default from Local Menu. Won't change to initial 
const LocalMenu = ({
    current,
    localEdit = {
        background_color: '#fffff',
        element_id: ''
    },
    colorpickview,
    onBackgroundChange,
    elements,
    onSlideUpdate,
    onResetApi,

    layout,
    templateId,
    onTemplateChange,
    // setTemplateId,
    typeName
}: LocalMenuProps) => {
    const { _id = '', element_id, group = '' } = localEdit;
    const board: any = useContext(boardContext);
    const { font_color, background, reset_to_default, layout_for_this_slide, pillow_local_setting_text, ...languages }: any = useContext(languageContext);
    // current_slide_options
    const [localElement, setLocalElement] = useState(elements || []);
    let color = '';
    let id = '';

    useEffect(() => {
        const element = document.getElementById('localani');
        if (element) element.classList.add("active");
    }, []);
    useEffect(() => setLocalElement(elements), [elements]);

    if (current === null && current?.product_template_type === undefined) return null;

    localElement.map((e: any) => {
        if (element_id === BACKGROUND_IMAGE) {
            if (e.group === group && e._id !== _id) {
                id = e._id;
                color = e.fill;
            }
        }
        if (e.group === PRESENTATION_LOGO) {
            if (e.group === group && e._id !== _id) {
                id = e._id;
                color = e.fill;
            }
        }
        if (e.element_id === END_CUSTOMER_LOGO) {
            if (e.group === group) {
                id = e._id;
                color = e.fill ? e.fill : board.editorLogo.background_color;
            }
        }

        if (e.element_id === TITLE_TEXT) {
            if (e._id === _id) {
                id = e._id;
                color = e.color;
            }
        }
        if (e.element_id === TITLE_TEXT_BACKGROUND) {
            if (e._id === _id) {
                id = e._id;
                color = e.fill;
            }
        }
        return null;
    });

    const templateChange = (_id: any, name: any) => {
        /**
         * ISSUE
         * Reset to default button disappeared after clicking
         * --------------------------------------------
         * after blocking setTemplateId, mentioned problem was fixed
         * couldn't find the cause what code was there in first place
         */
        // setTemplateId(_id);
        const { _id: slideid } = current;
        onTemplateChange(name, slideid);
    }

    const backgroundflag = element_id === BACKGROUND_IMAGE;
    const logoflag = backgroundflag
        || element_id === PRESENTATION_LOGO
        || element_id === END_CUSTOMER_LOGO
        || element_id === END_CUSTOMER_LOGO_BG
        || element_id === TITLE_TEXT_BACKGROUND;
    const textFlag = element_id === 'title_text';

    const whichValue = localEdit?.element_id === 'end_customer_logo_background'
        ? board.editorLogo?.background_color
        : board.presentationLogo?.background_color;
    const style = {
        background: color ? color : textFlag ? board?.look?.font_color : whichValue
    };

    function getName(element_id: any) {
        let name = '';
        if (element_id === 'background_image') {
            name = 'background_color'
        }
        if (element_id === 'title_text') {
            name = 'title_text'
        }
        if (element_id === 'presentation_logo') {
            name = 'presentation_logo_background'
        }
        if (element_id === 'end_customer_logo') {
            name = 'end_customer_logo_background'
        }
        if (element_id === 'end_customer_logo_background') {
            name = 'end_customer_logo'
        }
        if (element_id === 'title_text_background') {
            name = 'title_text_background'
        }
        return name || '';
    }

    const colorpick = () => {
        let name = getName(element_id);
        colorpickview({
            color: color ? color : textFlag ? board.look.font_color : whichValue,
            name,
            id: element_id === TITLE_TEXT ? _id : id
        });
    };

    const localMenuReset = () => {
        const retain = false;
        if (element_id === 'presentation_logo') {
            const resetData: any = [];
            elements.filter((e: any) => e.group === localEdit.group).map((e: any) => {
                if (e.type === 'shape') {
                    resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                    resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                } else {
                    resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                }
                return null;
            });
            onSlideUpdate(resetData);
        }
        if (element_id === 'title_text_background') {
            const elements = localEdit.slideType === 'title_slide'
                ? [localEdit.element_id, 'end_customer_logo_background']
                : [localEdit.element_id];
            onResetApi({ slide_type: localEdit.slideType, elements });
        }
        if (element_id === 'background_image') {
            onResetApi({ slide_type: localEdit.slideType, elements: ['background_color', 'background_image'] });
        }

        if (element_id === 'end_customer_logo_background') {
            // TODO :: after reset localMenu color not changing 
            const resetData: any = [];
            elements.filter((e: any) => e.group === localEdit.group).map((e: any) => {
                if (e.type === 'shape') {
                    resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                    resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                } else {
                    resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                    resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                }
                return null;
            });
            onSlideUpdate(resetData);
        }
        if (element_id === 'title_text') {
            onResetApi({ slide_type: localEdit.slideType, elements: ['title_text'] });
        }
        return null;
    }

    function left_local() {
        // CONTACT SLIDE :: NO LOCAL MENT
        if (current?.type === "contact_slide") return null;
        /**
        * PRODUCT SLIDE
        * IF LOCAL EDIT VALUE IS EMPTY DIPLAY 
        * TEMPLATE LAYOUT LIST
        */
        //  && localEdit._id === undefined
        /**
        * PRODUCT SLIDE :: DISPLAY LAYOUT OPTION 
        */
        if (current?.type === "product_slide") return (
            <div className="local__left product_slide_label">
                <label className="local__label">{layout_for_this_slide}:</label>
                {layout.map(({ _id, template_type, name, local_thumb }: any) => {
                    let flag = false;
                    if (current?.product_template_type !== null) {
                        flag = template_type === current?.product_template_type;
                    }
                    return (
                        <span key={_id} className={flag ? 'localmen-template-list active' : 'localmen-template-list'} onClick={flag ? () => { } : () => templateChange(_id, name)}>
                            <img src={flag ? local_thumb.active : local_thumb.inactive} alt={name} />
                        </span>
                    )
                })}
            </div>
        );
        // OTHER SLIDES
        return (
            <div className="local__left">
                {textFlag && (<label className="local__label">{font_color}:</label>)}
                {textFlag && (<button className="local__color" style={style} onMouseUp={colorpick}>&nbsp;</button>)}

                {logoflag && (<label className="local__label">{background}:</label>)}
                {logoflag && (<button className="local__color" style={style} onClick={colorpick}>&nbsp;</button>)}
                {backgroundflag && (
                    <button className="local__image" onClick={() => onBackgroundChange()}>
                        <img src={localImageIcon} alt="visible" />
                    </button>
                )}
            </div>
        )
    }

    function right_local() {
        if (current?.type === "contact_slide") return null;
        let resetHtml: any = '';
        if (current && current?.type === "product_slide") {
            layout.map((ll: any) => {
                if (ll._id === templateId) {
                    const { name = "", presentation_type_id } = ll;
                    resetHtml = <button className="flex local__reset" onClick={() => templateChange(presentation_type_id, name)}><img src={localResetIcon} alt="reset to default layout" />{reset_to_default}</button>;
                }
                return <div className="flex">{resetHtml}</div>;
                // return null;
            });
        } else
            resetHtml = <button className="flex local__reset" onClick={localMenuReset}><img src={localResetIcon} alt="reset to default." />{reset_to_default}</button>;
        return <div className="flex">{resetHtml}</div>;
    }

    // PILLOW TYPE LOCAL MENU
    if (typeName === 'pillow' && current !== null && current?.product_template_type !== undefined && current?.type === 'product_slide') {
        return (
            <section id="localani">
                <img className="leftConRbn" src={localCorner} alt="" />
                <div className="local">
                    <div className="flex local_inner text-regular_small align-center line-height_1rem">
                        {/* {pillow_local_setting_text} */}
                        <span className='gotham-bold'><b>Tip:</b></span> <TipDisplay tiplist={board.tips} language={languages} />
                    </div>
                </div>
                <img className="rightConRbn" src={localCorner} alt="" />
            </section>
        )
    }

    return (
        <section id="localani">
            <img className="leftConRbn" src={localCorner} alt="" />
            <div className="local">
                <div className="flex local_inner justify-space-between">
                    {left_local()}
                    {right_local()}
                </div>
            </div>
            <img className="rightConRbn" src={localCorner} alt="" />
        </section>
    );
};
export default React.memo(LocalMenu);
