import axiosInstance from '../../lib/axios';
import urls from '../';

// ## SLIDE PROPERTY UPDATE
export const postUpdateSlideProperty = (
    base: any,
    payload: any,
    slide: any,
    client_id: any,
    extra: any,
    auth: any,
    state: any
) => {
    const { current, setCurrent, sslides, setSlides } = state;
    const urll = `${base}/${urls.postUpdateSlideProperty}?slide=${slide}`;
    return axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        const computeData = Array.isArray(payload) ? payload : [payload];
        const elements = current.elements.map((e: any) => {
            let nn: any = {};
            let flag = false;
            computeData.map((f: any) => {
                if (f.id === e._id) {
                    nn = { ...e, ...nn, [f.type]: f.value };
                    flag = true;
                }
                return null;
            });
            if (flag) return nn;
            return e;
        });

        const newslide: any = sslides.map((s: any) => {
            if (s._id === current._id) {
                return { ...s, p: 1, elements };
            }
            return s;
        });
        setSlides(newslide);
        // changeCurrent({ ...current, elements });
        setCurrent({ ...current, elements });

        return { status: response.status };
    });
}
