// ** api/index.ts

import { baseUrl } from "./config";

//* GET ACCESS TOKENS
const accesskey = `${baseUrl}getAccesskey`;
const regenerateAccesskey = `${baseUrl}regenerateAccessKey`;
//* OVERVIEW
const getGetAll = `${baseUrl}presentationType/getAll`;
const getUserPresentations = `${baseUrl}presentation/getUserPresentations`;
const removePresentation = `${baseUrl}presentation/removePresentation`;

//* PRESENTATION DETAIL
const getUserPresentationDetail = `${baseUrl}presentation/getUserPresentationDetail`;

//* TITLE
const postUpdateTitle = `${baseUrl}presentation/updateTitle`;

//* SLIDE STRIP
const postChangeSlideVisibility = `${baseUrl}presentation/changeSlideVisibility`;
const deleteRemoveSlide = `${baseUrl}presentation/removeSlide`;

//* LOGO
const postSavePresentationLogoInfo = `${baseUrl}presentation/savePresentationLogoInfo`;
const postSaveCustomerLogoInfo = `${baseUrl}presentation/saveCustomerLogoInfo`;
const postAddImageSlide = `${baseUrl}presentation/addImageSlide`;

//* LOOK & FEEL
const postLookFeel = `${baseUrl}presentation/saveLookandFeel`;
//* TEMPLATE
const postLoadProductTemplate = `${baseUrl}defaultTheme/loadproductTemplate`;
const getProductTemplate = `${baseUrl}defaultTheme/getProductTemplates`;
//* ELEMENTS
const postProductElementInfo = `${baseUrl}presentation/saveProductElementInfo`;
//* DESIGNs
const postProductDesignInfo = `${baseUrl}presentation/saveProductDesignInfo`;

//* UPDATE SLIDE
const postUpdateSlideProperty = `${baseUrl}presentation/updateSlideElement`;

//* BACKGROUND IMAGES
const getBackgroundImages = `${baseUrl}assets/getBackgroundImages`;

//* ADMIN CALL / NOT REQUIRED
const postLoadDefaultTheme = `${baseUrl}defaultTheme/loadDefaultTheme`;
const getDefaultTheme = `${baseUrl}defaultTheme/getDefaultTheme`;
const saveDefaultTheme = `${baseUrl}defaultTheme/saveDefaultTheme`;

//* EDITABLE
const postEcqUpdate = `${baseUrl}presentation/updateEcqItems`;

//* PDF
const postPDF = `${baseUrl}presentation/createpdf`;
//* PTT
const postPTT = `${baseUrl}presentation/createppt`;

//* REARRANGE
const postReArrange = `${baseUrl}presentation/rearrangeSlide`;
//* RESET
const postReset = `${baseUrl}presentation/resetElement`;
//* THEME
const getThemes = `${baseUrl}presentation/getThemes`;
const postSwitchTheme = `${baseUrl}presentation/switchTheme`;
const getGlobalSetting = `${baseUrl}features/getglobalSettings`;

//* SHARE
const postEmailShare = `${baseUrl}presentation/emailAndShare`;
const postPresentationUpdate = `${baseUrl}presentation`;

//* UPLOAD BACKGROUND IMAGE
const postImageUpload = `${baseUrl}file/backgroundImages/upload`;

// TIPS GET ALL
const getAllTips = `${baseUrl}tips/get-all`;

// COLOR PREDEFINED FLAG
const putToggleColor = `${baseUrl}presentation/toggle-color/`;
const putUpdateColorStatus = `${baseUrl}color-scheme/update-status`;

// DELETE USER UPLOADED IMAGES
const deleteImages = `${baseUrl}file/backgroundImages`;


// SAVE COLOR
const saveColor = `${baseUrl}color-scheme/save-color`;
const deleteColor = `${baseUrl}color-scheme`;

const API_List = {
    accesskey,
    regenerateAccesskey,
    getGetAll,
    getUserPresentations,
    removePresentation,
    getUserPresentationDetail,
    postUpdateTitle,
    postChangeSlideVisibility,
    deleteRemoveSlide,
    postSavePresentationLogoInfo,
    postSaveCustomerLogoInfo,
    postAddImageSlide,
    postLookFeel,
    postLoadProductTemplate,
    getProductTemplate,
    postProductElementInfo,
    postProductDesignInfo,
    postUpdateSlideProperty,
    getBackgroundImages,
    postLoadDefaultTheme,
    getDefaultTheme,
    saveDefaultTheme,
    postEcqUpdate,
    postPDF,
    postPTT,
    postReArrange,
    postReset,
    getThemes,
    postSwitchTheme,
    getGlobalSetting,
    postEmailShare,
    postPresentationUpdate,
    postImageUpload,
    getAllTips,
    putToggleColor,
    putUpdateColorStatus,
    deleteImages,
    saveColor,
    deleteColor,
};

export default  API_List;
