import React, { useContext, useState, useRef } from 'react';
import ThemeContext from "../../../context/ThemeContext";
import languageContext from "../../../context/languageContext";

const PriceMargin = () => {
    const theme: any = useContext(ThemeContext);
    const language: any = useContext(languageContext);
    
    const [margin, setMargin] = useState(theme.margin || "");
    const [bottomAlert, setBottomAlert] = useState(false);

    const inputRef: any = useRef(null);

    const handleMarginChange = ({ target }: any) => {
        if (isNaN(target.value)) return null;
        setMargin(target.value)
    }

    const handleClick = () => {
        if (!margin || margin < 0 || margin > 99) {
            alert("Please enter valid number (between 0 and 100).");
            setMargin("");
            inputRef.current.focus();
        } else if (margin < 20) {
            const confirmStatus = window.confirm(`The margin you have selected seems low, are you sure you want to use ${margin}% as the default margin on your products ?`);
            if (confirmStatus) {
                updateMargin();
            } else {
                inputRef.current.focus();
            }
        } else {
            updateMargin();
        }
    }

    const updateMargin = async () => {
        let data = await theme.fetchPresentationUpdate({ margin })
        if (data?.status === 200) {
            setBottomAlert(true);
            setTimeout(() => setBottomAlert(false), 300);
        }
    }

    return (
        <div className="setting__element lookandfeel relative">
            <div className="setting__element__wrap">
                <p>{language.price_margin_description}</p>
                <div className="margin_input_container">
                    <span>{language?.magin.split(" ")[0]}</span>
                    <div className="margin_right">
                        <input ref={inputRef} className="margin_input" value={margin} onChange={handleMarginChange} />
                        <span>%</span>
                    </div>
                </div>
                <button className="flex flex-row justify-center align-center py14 px25 button_margin" onClick={handleClick}>{language.ok}</button>
            </div>

            {bottomAlert
                ? <div className="margin_bottom_text">
                    <span className="margin_checkmark">&#x2714;</span>️
                    {language.price_have_been_recalculated}
                </div>
                : null}
        </div>
    );
}
export default PriceMargin;
