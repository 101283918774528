// src/component/Panel/Theme/index.tsx
import React, { useState, useContext, useEffect } from 'react';

import themeContext from '../../../context/ThemeContext'

import ThemesDisplay from './ThemesDisplay';

const Themes = () => {
    const { themes, activetheme = 'default' }: any = useContext(themeContext);

    const tablist = [
        { label: 'general', group: 'general' },
        { label: 'brands', group: 'brand' }
    ];

    const [value, setvalue] = useState('');
    const [activetab, setactivetab] = useState('general');

    useEffect(() => {
        setvalue(activetheme);
        themes.forEach((theme: any) => {
            if (theme._id === activetheme) setactivetab(theme.group)
        })
    }, [themes, activetheme])

    return (
        <>
            <div className="tab_header flex">
                {tablist.map(({ label, group }: any) => {
                    const flag = activetab === group;
                    return (
                        <div
                            key={group}
                            role="button"
                            className={`gotham-medium ${flag ? "active" : ''}`}
                            onClick={() => {
                                if (!flag) setactivetab(group);
                            }}
                        >{label}</div>
                    );
                })}
            </div>
            <div className="setting__element setting__element__themes">
                <div className="setting__ele ment__wrap">
                    <div>
                        <div className='tab_content'>
                            <ThemesDisplay activetab={activetab} list={themes} value={value} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Themes;
