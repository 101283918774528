import axiosInstance from '../../lib/axios';
import urls from '../';

export const postProductElementInfo = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    payload: any,
    state: any
) => {
    const { element, setElement } = state;
    const urll = `${base}/${urls.postProductElementInfo}`;
    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        if (payload.pillow) {
            if (payload.element_change.element_id === 'colors') {
                setElement({
                    ...element,
                    colors: {
                        ...element.colors,
                        value: payload.element_change.value
                    }
                });
            }
            if (payload.element_change.element_id === 'style') {
                setElement({
                    ...element,
                    style: {
                        ...element.style,
                        applied: payload.element_change.applied,
                        value: payload.element_change.value
                    }
                });
            }
            if (payload.element_change.element_id === 'product_highlight') {
                if (payload.element_change.name === 'status') {
                    setElement({
                        ...element,
                        product_highlight: {
                            ...element.product_highlight,
                            status: payload.value
                        }
                    })
                } else {
                    setElement({
                        ...element,
                        product_highlight: {
                            ...element.product_highlight,
                            applied: payload.element_change.applied,
                            value: payload.element_change.value
                        }
                    });
                }
            }
        }
        else {
            const newELement: any = element.map((ele: any) => {
                if (ele._id === payload.id) {
                    return { ...ele, value: payload.value };
                }
                return ele;
            });
            setElement(newELement);
        }
        return { status: response.status };
    });
}
