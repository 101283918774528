// src/component/Panel/ProductSlideLayout/Thumb.tsx
import React, { useContext } from 'react'

import lookAndFeelContext from '../../../context/LookAndFeelContext';
import languageContext from '../../../context/languageContext';

const Thumb = ({ _id, template_type, name, thumb, flag }: any) => {
    const { onTemplateChange, setTemplateId }: any = useContext(lookAndFeelContext);
    const { select_layout, ...translation }: any = useContext(languageContext);

    const { active = '', inactive = '' } = thumb || {};

    function templateChange(_id: any, name: any) {
        setTemplateId(_id);
        onTemplateChange(name);
    }

    return (
        <div key={_id} className="slideTemplate_list" onClick={flag ? () => { } : () => templateChange(_id, name)}>
            <label className="">
                <img src={flag ? active : inactive} alt="" />
                &laquo;{translation[`${template_type}`]}&raquo;
            </label>
        </div>
    );
}
export default React.memo(Thumb);
