import axiosInstance from '../../lib/axios';
import urls from '../';
import { getStoreViewFromURL } from '../../helpers';

// ## PRESENTATIN TYPE LIST
export const getPresentationList = (
    base: string,
    clientId: string
) => {
    const storeview = getStoreViewFromURL();
    const urll = `${base}/${urls.getGetAll}`;
    return axiosInstance(urll, {
        method: 'GET',
        headers: { client_id: clientId },
        params: { storeview },
    }).then((response: any) => {
        const { data, translation } = response.data;
        return { status: response.status, data, translation };
    });
}
