// src/component/Panel/ShapeColor/ShkapeTab.tsx
import React, { useState, useContext, useEffect } from 'react'

import lookAndFeelContext from '../../../context/LookAndFeelContext';
import languageContext from '../../../context/languageContext';

import StyleIcon from '../../PillowSlide/atom/PT_SVG'

const ShapeTab = () => {
    const { element, onElementChangge }: any = useContext(lookAndFeelContext);
    const language: any = useContext(languageContext);
    const {
        style = { value: [] },
        product_highlight = { value: { highlight: [], status: true } }
    } = element;

    const [sty, setsty] = useState(style?.value || [])
    const [hlight, sethlight] = useState(product_highlight);

    useEffect(() => {
        setsty(style.value);
    }, [style])

    useEffect(() => {
        sethlight(product_highlight);
    }, [product_highlight])

    const onChange = (label: string, list: any, set: any, type?: any) => {
        const newSty = list.map((s: any) => {
            if (s.name === label) {
                return { ...s, status: true }
            }
            return { ...s, status: false };
        });
        if (type === "style") {
            onElementChangge({ ...element.style, value: newSty, applied: label })
        }
        if (type === "highlight") {
            const highlightObj = {
                ...element.product_highlight, value: {
                    ...element.product_highlight.value,
                    highlight: newSty
                }, applied: label
            }
            onElementChangge(highlightObj);
        }
    }

    return (
        <div>
            <h3 className="gotham-black fz16 fw-normal mb15">
                {language["style"] ? language["style"] : "Style"}
            </h3>
            <div className="flex justify-spac e-between style_wrap">
                {sty.filter((s:any) => s.name !== 'style_2').map(({ name, status, thumb }: any) => (
                    <div
                        key={name} role="button"
                        className={`style-item ${(status ? "active mr12" : 'mr12 cursor-pointer')}`}
                        onClick={() => {
                            if (!status)
                                onChange(name, sty, setsty, 'style')
                        }}
                    >
                        {name === 'style_1' && <StyleIcon.StyleOne />}
                        {name === 'style_2' && <StyleIcon.StyleTwo />}
                        {name === 'style_3' && <StyleIcon.StyleThree />}
                    </div>
                ))}
            </div>

            <div className='setting__divider'>
                <div className={"flex justify-space-between"}>
                    <h3 className="gotham-black fz16 fw-normal mb15">
                        {language["product_highlight"] ? language["product_highlight"] : "Product highlight"}
                    </h3>
                    <label className="cc_switch">
                        <input type="checkbox" checked={hlight.value.status} name="highlight" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const highlightObj = {
                                ...element.product_highlight, value: {
                                    ...element.product_highlight.value,
                                    status: e.target.checked, name: 'status'
                                }
                            }
                            onElementChangge(highlightObj)
                        }} />
                        <span className="slider_pillow cursor-pointer round"></span>
                    </label>
                </div>

                <div className="flex justify-space-between">
                    {hlight.value.highlight && hlight.value.highlight.map(({ name, status, thumb }: any) => {
                        return (
                            <div
                                className={`highlight-item ${status ? "active" : 'cursor-pointer'}`}
                                key={name}
                                role="button"
                                onClick={() => {
                                    if (!status)
                                        onChange(name, hlight.value.highlight, sethlight, 'highlight')
                                }}
                            >
                                {name === 'round' && <StyleIcon.CircleIcon />}
                                {name === 'square' && <StyleIcon.SquareIcon />}
                                {name === 'diamond' && <StyleIcon.DiamonIcon />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default React.memo(ShapeTab);
