// src/component/Panel/Theme/List.tsx
import React, { useContext } from 'react'

import themeContext from '../../../context/ThemeContext'

const ThemesDisplay = ({ activetab, list, value }: any) => {
    const { setactivetheme, fetchThemeUpdate }: any = useContext(themeContext);

    const onChange2 = (value: any) => {
        setactivetheme(value);
        fetchThemeUpdate(value);
    }

    const sort = (a: any, b: any) => {
        return a.order - b.order;
    }

    return (
        <div className="theme_select text-black">
            <ul>
                {list
                    .filter((z: any) => z.group === activetab)
                    .sort(sort)
                    .map(({ _id, name, background = '' }: any, index: number) => {
                        const style = background !== '' ? {
                            background: `url('${background}') no-repeat 0 0 #fff`
                        } : {};
                        if (_id === value)
                            return <li className='active' style={{ ...style }} value={_id} key={_id}>{name}</li>;
                        return <li style={style} value={_id} key={_id} onClick={() => onChange2(_id)}>{name}</li>;
                    })}
            </ul>
        </div>
    );
}
export default ThemesDisplay;
