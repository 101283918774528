import axiosInstance from '../../lib/axios';
import urls from '../';

export const postRearrange = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) => {
    const urll = `${base}/${urls.postReArrange}`;
    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: data,
    }).then((response: any) => {
        return { status: response.status };
    });
}
