import React  from "react";

import iconEye from "../../assets/icons/icon-eye.png";
import iconEyeOff from "../../assets/icons/icon-eye-off.png";
import iconDelete from "../../assets/icons/icon-delete.png";

import { storyItemProps } from "./stroyType";

const StoryItem = ({
    _id,
    activeClass,
    ii,
    name,
    visible = true,
    onActiveChange,
    onDeleteItem,
    onChangeVisible,
    handleDrag,
    handleDrop,
}: storyItemProps) => {
    function handleClick(alt: string) {
        switch (alt) {
            case "delete":
                onDeleteItem(_id);
                break;
            case "visible":
                onChangeVisible(_id, !visible);
                break;
            default:
        }
    }
    return (
        <a
            href="#void"
            draggable={true}
            className={`strip__item p-t_5 ${activeClass}`}
            key={ii}
            data-order={ii}
            id={`stripitem_${ii + 1}`}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
        >
            <div className="strip__item__wrap">
                <div
                    className="strip__item__button"
                    onClick={() => onActiveChange(ii)}
                >
                    <span className="strip__item__index">{ii + 1}</span>
                    <img
                        id={`strip_${ii + 1}`}
                        className="strip__item__image"
                        alt="thumb"
                    />
                    {!visible && <div className="opacitonwrap"></div>}
                    {!visible && (
                        <img
                            className="strip__item__indicator"
                            src={`${iconEyeOff}`}
                            alt="visible"
                        />
                    )}
                </div>

                <span className="strip__item__name text-regular_small p-t_5">{name}</span>
                <div className="strip__action">
                    {!visible ? (
                        <button onClick={() => handleClick("visible")}>
                            <img src={`${iconEyeOff}`} alt="visible" />
                        </button>
                    ) : (
                        <button onClick={() => handleClick("visible")}>
                            <img src={`${iconEye}`} alt="visible" />
                        </button>
                    )}
                    <button onClick={() => handleClick("delete")}>
                        <img src={`${iconDelete}`} alt="delete" />
                    </button>
                </div>
            </div>
        </a>
    );
};
export default React.memo(StoryItem);
