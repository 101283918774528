import React, { useEffect, useState, useContext } from "react";
// import { cloneDeep } from "lodash";
import languageContext from "../../context/languageContext";
import StoryItem from "./StoryItem";
import { StoryProps, locProps, locSlideType } from "./stroyType";
// import { leftCornerShadow } from "../../helpers/IconList";
import { postRearrange } from "../../api/postRearrange/postRearrange";
import { catcchErrorHandling } from "../../helpers";
import { addSlide, addSlide2 } from '../../helpers/IconList'
import { deepCloneObject } from "../../lib/lodash";

const Story = ({
    index,
    sliders,
    change,
    onDeleteItem,
    onVisibleItem,
    onAddImageSlide,
    setSlides,
    // setActiveSlide,
    setCurrent,
    baseUrl,
    clientID,
    extraKey,
    // acckey,
    presentation,
    setLoading,
    setError,
    thumbAction,
    thumbGenerator,
    setImageSlide2
}: StoryProps) => {
    const [locSlider, setLocSlider] = useState(sliders || []);
    const [dragId, setDragId] = useState<any>();
    const [addsrc, setaddsrc] = useState(addSlide);

    const language: any = useContext(languageContext);

    useEffect(() => setLocSlider(sliders), [sliders]);

    useEffect(() => {
        if (locSlider.length) {
            let order: any = null;
            locSlider.map(({ order_no, type }: locSlideType) => {
                if (type === 'image_slide2') {
                    order = order_no;
                }
                return null;
            });
            setImageSlide2(order);
        }
    }, [locSlider, setImageSlide2]);

    function add() {
        onAddImageSlide();
    }

    const handleDrag = (ev: any) => {
        const title = ev.currentTarget.dataset.order * 1;
        setDragId(title);
    };
    const handleDrop = (ev: any) => {
        const title = ev.currentTarget.dataset.order;
        const targetID = title * 1;
        const from = sliders[`${dragId}`];
        // const copyArray = cloneDeep(sliders);
        const copyArray = deepCloneObject(sliders);
        const norslides = copyArray.filter(
            (xx: any, index: any) => index !== dragId
        );
        norslides.splice(targetID, 0, from);
        //* creaing order_no with index value
        const orderReqObj: any = [];
        const organized = norslides.map((x: any, index: any) => {
            const order_no = index + 1;
            orderReqObj.push({ _id: x._id, order_no });
            return { ...x, order_no };
        });

        const req = { presentation, slides: orderReqObj };
        const accessToken: any = thumbAction();
        accessToken.then(({ accesskey }: any) => {
            postRearrange(baseUrl, clientID, extraKey, accesskey, req)
                .then(({ status }: any) => {
                    if (status === 200) {
                        setSlides(organized);
                        setCurrent(organized[`${index}`]);
                        thumbGenerator(false, false, 100);
                    }
                })
                .catch((err: any) => {
                    setLoading(0);
                    const msg = catcchErrorHandling(err);
                    setError(msg);
                });
        })
    };

    return (
        <aside id="story" className="strip">
            <div className="strip__add_image">
                <button className="strip__add__btn" onClick={add}>
                    <img
                        src={addsrc}
                        alt="Add Slide"
                        onMouseEnter={() => setaddsrc(addSlide2)}
                        onMouseLeave={() => setaddsrc(addSlide)}
                    />
                </button>
            </div>
            {/* <div className="strip__add">
                <button className="strip__add__btn" onClick={add}>
                    <span>{language.image_slide}</span>
                </button>
                <img src={leftCornerShadow} alt="" />
            </div> */}

            <div className="strip__wrap">
                <div className="strip__container">
                    {locSlider && locSlider.map((sld: locProps, ii: any) => (
                        <StoryItem
                            {...sld}
                            activeClass={index === ii ? "active" : ""}
                            key={`r-${ii}`}
                            ii={ii}
                            onActiveChange={change}
                            onDeleteItem={onDeleteItem}
                            onChangeVisible={onVisibleItem}
                            handleDrag={handleDrag}
                            handleDrop={handleDrop}
                            name={language[sld.type]}
                        />
                    ))}
                </div>
            </div>
        </aside>
    );
};
export default Story;
