import axiosInstance from '../../lib/axios';
import urls from '../';

export const postSwitchTheme = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    data: any
) => {
    const urll = `${base}/${urls.postSwitchTheme}`;
    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data,
    }).then((response: any) => {
        const { status, data } = response;

        let product_slide_elements: any = {};
        data.data.product_slide_elements.forEach((pse: any) => {
            if (pse.element_id === 'style') {
                pse['applied'] = "style_1"
                pse.value.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            } else if (pse.element_id === 'product_highlight') {
                pse['applied'] = "round"
                pse.value.highlight.map((v: any) => {
                    if (v.status) pse.applied = v.name;
                    return null;
                });
            }
            product_slide_elements[pse.element_id] = { ...pse };
            return null;
        });
        return "pillow" === response.data.data.type ? {
            status,
            ...data.data,
            product_slide_elements,
        } : { status, ...data.data };
    });
}
