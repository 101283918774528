import axiosInstance from "../../lib/axios";
import urls from '../';

/**
 * Delete Custom Color
 */
export function deleteColor(base: any, presentationId: any, colorId: any, client_id: any, extra: any, auth: any) {
    const urll = `${base}/${urls.deleteColor}/${colorId}`;

    return axiosInstance(urll, {
        method: 'DELETE',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`
        },
        params: {
            presentationId
        }
    }).then((response: any) => {

            const {
                status,
                data:{ success, currentPresentation }
            } = response;

            let product_slide_elements: any = {};
            currentPresentation.product_slide_elements.forEach((pse: any) => {
                if (pse.element_id === 'style') {
                    pse['applied'] = "style_1"
                    pse.value.map((v: any) => {
                        if (v.status) pse.applied = v.name;
                        return null;
                    });
                } else if (pse.element_id === 'product_highlight') {
                    pse['applied'] = "round"
                    pse.value.highlight.map((v: any) => {
                        if (v.status) pse.applied = v.name;
                        return null;
                    });
                }
                product_slide_elements[pse.element_id] = { ...pse };
                return null;
            });

            return { status, success, colorId, currentPresentation, product_slide_elements };
        });
}
