import axiosInstance from '../../lib/axios';
import urls from '../';

export const postPTT = (
    base: any,
    client_id: any,
    extra: any,
    auth: any,
    request: any
) => {
    const urll = `${base}/${urls.postPTT}`;
    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        // responseType: 'arraybuffer',
        responseType: 'blob',
        data: request,
    }).then((response: any) => {
        const fileName = `${request._id}.ppt` || 'file.ppt';
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE11 and the legacy version Edge support
            let blob = new Blob([response.data], { type: 'text/html' });
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // other browsers
            let bl = new Blob([response.data], { type: 'text/html' }),
                a = document.createElement('a');
            a.href = URL.createObjectURL(bl);
            a.download = fileName;
            a.hidden = true;
            document.body.appendChild(a);
            a.click();
        }

        console.log('PDF request success');
        return true;
    });
}
