import axiosInstance from '../../lib/axios';
import urls from '../';

/*
 * GET access for Application
 * @param base - API address
 * @param clientId - User / client ID
 * @param params - Extra key
 * @param presentation_type - Type of presentiaion
 */
export const getAccessKey = (
    base: string,
    clientId: string,
    extras: any,
    presentation_type: string
) => {
    const urll = `${base}/${urls.accesskey}`;
    return axiosInstance(urll, {
        method: 'GET',
        headers: {
            client_id: clientId,
            presentation_type,
        },
        params: {
            grant_type: 'access_key',
            auth: 'basic',
            extras,
        },
    }).then((response: any) => {
        const {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features,
            presentationType,
            expirytime = '',
        } = response.data;

        return {
            accesskey,
            extraKey,
            fonts,
            templateColor,
            features, //: fmenu,
            presentationType,
            expirytime,
        };
    });
}
