/**
 * src/component/BackgroundImage/FolderDisplay.tsx
 */

import React, { useState } from 'react'

import { folderIcon, folderIcon2, uploadFolderIcon } from '../../helpers/IconList';

function FolderDisplay({ index, name, makepath, list }: any) {
    const [folder, setfolder] = useState(folderIcon);
    const [upload, setupload] = useState(uploadFolderIcon);
    const flag = index === 0 && list === 0;

    return (
        <FolderButton
            name={name}
            index={index}
            makepath={makepath}
            icon={flag ? upload : folder}
            setfolder={flag ? setupload : setfolder}
            defImage={flag ? uploadFolderIcon : folderIcon}
        />
        )
}

function FolderButton({
    name,
    index,
    makepath,
    icon,
    setfolder,
    defImage
}: any) {
    return (
        <button key={name} onClick={() => {
            makepath(`${index}`);
        }} className={`itemCss folderIcon`}>
        <img
            src={icon}
            onMouseOver={() => {
                setfolder(folderIcon2);
            }}
            onMouseOut={() => {
                setfolder(defImage);
            }}
            alt={name}
            style={{ height: 'auto' }}
        />
        {name}
    </button>
    )

}

export default FolderDisplay;
