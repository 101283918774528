import axiosInstance from "../../lib/axios";
import urls from '../';

/**
 * Save Custom Color
 */
export function saveColor(base:any, data: any, client_id: any, extra: any, auth: any) {
    const urll = `${base}/${urls.saveColor}`;

    return axiosInstance(urll, {
        method: 'post',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`
        },
        data,
    }).then((response: any) => {
            const { status, data: { message, success, data } } = response;
            console.log(message);
            return { status, success, data };
        }).catch((error: any) => {
            return Promise.reject(error);
        }); 
}
