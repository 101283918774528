import React, { useState, useContext, useRef, useEffect } from 'react';
import languageContext from '../../../context/languageContext'
import boardContext from '../../../context/BoardContext';

export default function PTMargin({ element, onSlideUpdate, cssStyle = '', style = {} }: any) {

    const { _id = 0, draw_value = '', default_value, text_align, element_id, allow_translation = false } = element;
    // font_size = 10, font_name, font_weight, 
    const lang: any = useContext(languageContext);
    const { margin = 0 }: any = useContext(boardContext);

    const [edit, setEdit] = useState(true);
    const val = allow_translation ? lang[element_id] || draw_value : draw_value;
    const [value, setvalue] = useState(val);
    const inputRef: any = useRef(null);



    useEffect(() => {
        if (!edit)
            inputRef.current.focus()
    }, [edit])

    useEffect(() => {
        if (draw_value && margin && default_value && !allow_translation) {
            const updatedNetMargin = (default_value / (1 - margin / 100)).toFixed(2).toString();
            const priceWithCurrency = formatPriceWithCurrency(draw_value, updatedNetMargin)
            setvalue(priceWithCurrency);
        } else {
            setvalue(draw_value)
        }
    }, [margin, draw_value, default_value, allow_translation])

    const inputStyle = {
        // fontSize: `${font_size}px`,
        // fontWeight: `${font_weight}`,
        // fontFamily: font_name || '',
        // lineHeight: '16px',
        // background: 'none',
        // color: '#fff',
        textAlign: text_align,
        ...style
    }

    function toggleEdit(flag: boolean) {
        setEdit(flag);
        setTimeout(function () {
            const ele = document.getElementById('texttext');
            ele?.focus();
        }, 200)
    }

    function onLeave() {
        setTimeout(function () {
            toggleEdit(true);
        }, 200)
        /**
         * Condition that value is different 
         * make API call
         */
        if (draw_value !== value) {

            /*
             * After editing translated label/ text
             * edited text should be displayed 
             * for that allow_translation to false
             */
            if (allow_translation) {
                onSlideUpdate([
                    // { id: _id, type: 'allow_translation', value: false },
                    { id: _id, type: 'draw_value', value }
                ]);
            } else {
                onSlideUpdate([
                    { id: _id, type: 'allow_translation', value: true },
                    { id: _id, type: 'draw_value', value }
                ]);
            }
        }


    }

    function focushere(e: any) {
        if (draw_value !== null) {
            e.target.selectionStart = value.length;
            e.target.selectionEnd = `${value.length}`;
        }
    }

    function handleChange(e: any) {
        setvalue(e.target.value);
    }

    function formatPriceWithCurrency(draw_value: any, updatedAmount: any) {
        //If draw value is in ',' seperated amount then updating updatedAmount to include ','
        if (draw_value.includes(",")) {
            updatedAmount = updatedAmount.replace(".", ",");
        }
        const getAmount = draw_value.replace(/[^0-9.,]/g, '');
        return draw_value.replace(getAmount, updatedAmount);
    }

    // return null;

    return (
        <>
            {edit
                ? <>
                    <span role='button' className={`${cssStyle} hoverFormELe`} style={inputStyle} onClick={() => {
                        toggleEdit(false)
                    }}>{value}</span>
                </>
                : <input
                    ref={inputRef}
                    type='text'
                    id="texttext"
                    name="texttext"
                    className={`${cssStyle} text-line-height hover_Form_ELe`}
                    style={{ ...style, outline: '1px dashed #fff', background: 'none' }}
                    value={value}
                    onBlur={onLeave}
                    onFocus={focushere}
                    onChange={handleChange}
                />
            }
        </>
    )
}