/*
 * component: Message
 */

import React, { useState, useEffect, useContext } from 'react';
import Button from '../_atom/Button/Button';

import MessageContext from '../../context/MessageContext';

import { ICON_ARROW } from '../BackgroundImage/icons';
import './message.css';

const Message: React.FC<any> = () => {
    // MOVE MESSAGE BOX OUT OF SITE
    const offset = '-100%';
    // TIME IN SECONDS TO DISPLAY MESSAGE BOX
    const time = 150;
    
    const { display = false, type, message, fn, setMessage }= useContext(MessageContext);
    const [top, setTop] = useState(offset);

    useEffect(() => {
        if (display) {
            const timeId = setTimeout(() => {
                setTop('200px')
            }, time);
            return () => {
                clearTimeout(timeId);
            };
        }
    }, [display]);

    if (!display) return null;

    const close = () => {
        setTop(offset);
        setMessage({
            display: false,
            message: '',
            fn: () => {},
        })
    }

    const ok = () => {
        if (type === 'confirm') {
            fn();
        }
        setTop(offset);
        setMessage({
            display: false,
            type: 'normal',
            message: '',
            fn: () => {}
        });
    }

    return (
        <React.Fragment>

            <div className="message_overlay flex justify-center align-center">
                <div
                    className="flex flex-column justify-space-between message_box"
                    style={{
                        color: '#fff',
                        top: top
                    }}
                >
                    <div className="message_body" dangerouslySetInnerHTML={{ __html: `<p>`+message+`</p>` }} />

                    <div className="message_footer flex justify-center">
                        {type === "confirm" && (
                            <Button sty="mr20 btn-cancel" click={close}>{ICON_ARROW}Cancel</Button>
                        )}
                        <Button click={ok}>Ok</Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Message;


