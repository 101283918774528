import axiosInstance from '../../lib/axios';
import urls from '../';

// ## LOOK AND FEEL SETTING
export const postSaveLookAndFeel = (
    base: any,
    payload: any,
    presentationId: any,
    client_id: any,
    extra: any,
    auth: any,
    state: any
) => {
    const { look, setLook, setPresentationFont } = state;
    const urll = `${base}/${urls.postLookFeel}?presentation=${presentationId}`;
    return axiosInstance(urll, {
        method: 'POST',
        headers: {
            client_id,
            'x-extra-token': `${extra}`,
            authorization: `Bearer ${auth}`,
        },
        data: payload,
    }).then((response: any) => {
        setLook({ ...look, [payload.type]: payload.value });
        if (payload.type === "font_name") {
            setPresentationFont(payload.value);
        }

        return { status: response.status };
    });
}
